import React from "react";

import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import clsx from "clsx";
import orderBy from "lodash/orderBy";

import Card from "../components/Card";
import Headline from "../components/Headline";
import Layout from "../components/Layout";
import SectionContainer from "../components/SectionContainer";
import { SEO } from "../components/SEO";
import useTheme from "../hook/useTheme";
import { dateDisplay } from "../shared/utils/dateDisplay";
import { getHeroSectionPreviewImageSrc } from "../shared/utils/image";
import { removeDomElement } from "../shared/utils/removeDomElement";
import { createNewsDetailsUrl } from "../shared/utils/urls";

const News = ({ data }) => {
  const theme = useTheme(2);
  const articles = data?.directus?.article ?? [];

  const now = new Date();

  const articlesFiltered = articles.filter(({ date_published }) =>
    date_published ? new Date(date_published) <= now : true
  );

  const articlesFilteredAndSorted = orderBy(
    articlesFiltered,
    ({ date_published, date_created }) =>
      new Date(date_published ?? date_created),
    "desc"
  );

  return (
    <Layout theme={theme} pageTitle="Aktualności">
      <SEO
        urlPath="/aktualnosci/"
        imagePath={getHeroSectionPreviewImageSrc(data)}
        description="Aktualności strony Miejskiego Schroniska dla Zwierząt w Tychach. Sprawdź co się aktualnie u nas dzieje."
      />
      <div className="px-2 pt-8 md:px-8 xl:px-16">
        <SectionContainer
          className={clsx(
            theme.bgStyleLight,
            " mx-auto rounded-t-md px-3 py-5"
          )}
        >
          <Headline
            title="Aktualności"
            level={1}
            className={clsx("mb-4 font-bold")}
          />
          <div className="mx-auto grid grid-cols-1 gap-6 p-4 md:grid-cols-2 lg:grid-cols-3">
            {articlesFilteredAndSorted.map(
              ({
                id,
                title,
                content,
                date_created,
                date_published,
                main_photo,
              }) => (
                <Card
                  key={id}
                  linkTo={createNewsDetailsUrl(id)}
                  theme={theme}
                  variant="yellow-900"
                  title={title}
                  subtitle={dateDisplay(date_published ?? date_created)}
                  descriptionHtml={removeDomElement(content ?? "")}
                  gatsbyImage={getImage(main_photo?.imageFile)}
                  gatsbyImageAlt={main_photo?.description}
                />
              )
            )}
          </div>
        </SectionContainer>
      </div>
    </Layout>
  );
};

export default News;

export const query = graphql`
  query NewsPage {
    directus {
      article {
        id
        date_created
        date_updated
        date_published
        status
        title
        content
        main_photo {
          id
          description
          imageFile {
            size
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                width: 500
                aspectRatio: 1.4
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
      hero_section {
        hero_image {
          id
          imageFile {
            previewImage: childImageSharp {
              resize(
                width: 1200
                height: 630
                quality: 85
                cropFocus: ATTENTION
                fit: COVER
              ) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
